import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ModalContact from 'aulatime/src/components/ModalContact';
import SectionCursos from 'aulatime/src/components/SectionCursos';

import DiagonalLine from 'aulatime/src/components/DiagonalLine';

const CursosBonos2 = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            station {
              metadata {
                domain
                shortcode
              }
            }
          }
        }
      }
    `
  );

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'es' },
      ...{ form: 'book', section: '#Bonos' }
    }
  };

  const contactHeader = '¡Reserva ya!';
  const button = (onClick) => (
    <button type="button" className="btn btn-lg btn-primary" onClick={onClick}>¡Lo quiero!</button>
  );

  const col1 = <div className="col">

    <div className="card card-03">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <div>
                <h5>25 horas</h5>
              </div>
              <DiagonalLine/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>30 horas</h4>
              <h6>de tutoría privada</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3">
          <h5>€299,<span>99</span></h5>
          <p className="small">IVA incl.</p>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">Establecer una base sólida de vocabulario</li>
          <li className="list-group-item">Mejorar tu acento y práctica para hablar como un nativo</li>
          <li className="list-group-item">Prácticas de todas las destrezas para el examen</li>
        </ul>
        <div>
          <ModalContact
            header={contactHeader}
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#Bono30Horas"
          />
        </div>
      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  const col2 = <div className="col">
    <div className="card card-02">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <div>
                <h5>30 horas</h5>
              </div>
              <DiagonalLine/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>40 horas</h4>
              <h6>de tutoría privada</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3">
          <h5>€389,<span>99</span></h5>
          <p className="small">IVA incl.</p>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">Establecer una base sólida de vocabulario</li>
          <li className="list-group-item">Mejorar tu acento y práctica para hablar como un nativo</li>
          <li className="list-group-item">Prácticas de todas las destrezas para el examen</li>
        </ul>
        <div>
          <ModalContact
            header={contactHeader}
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#Bono40Horas"
          />
        </div>
      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  const col3 = <div className="col">

    <div className="card card-03">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <div>
                <h5>40 horas</h5>
              </div>
              <DiagonalLine/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>50 horas</h4>
              <h6>de tutoría privada</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3">
          <h5>€479,<span>99</span></h5>
          <p className="small">IVA incl.</p>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">Establecer una base sólida de vocabulario</li>
          <li className="list-group-item">Mejorar tu acento y práctica para hablar como un nativo</li>
          <li className="list-group-item">Prácticas de todas las destrezas para el examen</li>
        </ul>
        <div>
          <ModalContact
            header={contactHeader}
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#Bono50Horas"
          />
        </div>
      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  const header = <div className="container">
    <div className="row">
      <div className="col-lg-10 offset-lg-1 col text-center">
        <p>Los siguientes bonos de clases son recomendables para un curso intensivo de inglés general y preparación para los exámenes oficiales de Cambridge, Aptis y IELTS.</p>
      </div>
    </div>
  </div>

  return (
    <SectionCursos
      id="cursos-bonos-2"
      header={header}
      cols={[col1, col2, col3]}
    />
  );
}

export default CursosBonos2;
