import React from 'react';

import SectionTestimonios from 'aulatime/src/components/SectionTestimonios';

const items = [
  {
    key: '01',
    quote: <blockquote className="blockquote">
      <p>Siempre busca la manera de que aprendas. Clases amenas y muy divertidas. Al principio dudaba por ser online pero es de total confianza. 100% recomendable. Me estoy preparando para el B2 y estoy aprendiendo un montón.</p>
      <footer className="blockquote-footer mt-4">Marta G., Benalmádena</footer>
    </blockquote>
  },
  {
    key: '02',
    quote: <blockquote className="blockquote">
      <p>Las clases con Alma son muy prácticas, hablas muchísimo en cada clase, y la hora se te pasa volando. Alma es muy profesional, super agradable y siempre hay un tema de conversación con ella. Luego de estar trabajando con ella mi soltura y animarme a hablar en público en inglés, ahora voy a preparar el examen para validar mis conocimientos.</p>
      <footer className="blockquote-footer mt-4">Luciana, Bahrain</footer>
    </blockquote>
  },
  {
    key: '03',
    quote: <blockquote className="blockquote">
      <p>Estoy muy contento con Alma, es toda una profesional, muy atenta y divertida. Se me pasa el tiempo volando. Siempre tenemos temas de conversación y nos reímos mucho.</p>
      <footer className="blockquote-footer mt-4">Alberto, Málaga</footer>
    </blockquote>
  },
  {
    key: '04',
    quote: <blockquote className="blockquote">
      <p>Una gran experiencia, Alma la profesora me ayudó a lograr mi objetivo en tiempo record. 100% recomendada :)</p>
      <footer className="blockquote-footer mt-4">Marta T., Torremolinos</footer>
    </blockquote>
  },
  {
    key: '05',
    quote: <blockquote className="blockquote">
      <p>Profesora excelente. Atención personalizada y comunicación clara. Aprobé el examen de C1 de Cambridge. 100% recomendado.</p>
      <footer className="blockquote-footer mt-4">Beatriz, Málaga</footer>
    </blockquote>
  },
  {
    key: '06',
    quote: <blockquote className="blockquote">
      <p>Me han encantado las clases con Alma! Es buena profesora y muy profesional.</p>
      <footer className="blockquote-footer mt-4">Angelique, Torremolinos</footer>
    </blockquote>
  },
  {
    key: '07',
    quote: <blockquote className="blockquote">
      <p>Llevo poco más de un mes con las clases online y estoy encantado. Alma es una gran profesional que hace las clases no solo didácticas sino también amenas. 10 points for her!</p>
      <footer className="blockquote-footer mt-4">Isaac, Torremolinos</footer>
    </blockquote>
  },
  {
    key: '08',
    quote: <blockquote className="blockquote">
      <p>Grandes profesionales. Me gusta que las clases son amenas y personalizadas a mis necesidades. ¡Muy recomendable!</p>
      <footer className="blockquote-footer mt-4">Arancha, Torremolinos</footer>
    </blockquote>
  },
  {
    key: '09',
    quote: <blockquote className="blockquote">
      <p>Aprender con Alma es super fácil y ameno, la hora se te pasa volando. Como profesora es muy profesional, atenta, organizada y no se le escapa una. Como persona es super agradable, simpatica y divertida, siempre tenemos tema de conversación.</p>
      <footer className="blockquote-footer mt-4">Maria, Torremolinos</footer>
    </blockquote>
  },
  {
    key: '10',
    quote: <blockquote className="blockquote">
      <p>Llevo desde últimos de año dando clases con la profesora Alma y no puedo estar más contenta, es una profesional muy cualificada y muy atenta, facilita la enseñanza del inglés desde una didáctica fácil, entretenida y divertida. Yo doy las clases online y es como si las diera presencial, sin duda un acierto seguro.</p>
      <footer className="blockquote-footer mt-4">Marta, Torremolinos</footer>
    </blockquote>
  },
  {
    key: '11',
    quote: <blockquote className="blockquote">
      <p>Las clases son muy dinámicas, centradas y entretenidas ya que la hora de clase no se te hace pesada. Además de tiempo en clase con ella cunde porque estamos trabajando todo el tiempo de una u otra forma.</p>
      <footer className="blockquote-footer mt-4">Carmen, Málaga</footer>
    </blockquote>
  }
];

const ChunkTestimonios = () => (
  <SectionTestimonios items={items}/>
);

export default ChunkTestimonios;
