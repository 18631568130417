import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Link } from 'react-scroll';

import SectionDigital from 'aulatime/src/components/SectionDigital';

const ChunkDigital = () => {

  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "img_online.png" }) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <SectionDigital>
      <div className="container">
        <div className="row">

          <div className="col text-center">
            <h1 className="text-white">Clases Online</h1>
          </div>

        </div>
      </div>
      <div className="container">
        <div className="row">

          <div className="col-lg-6 col-md-5 col-12 d-flex">
            <Img
              fixed={data.img.childImageSharp.fixed}
              className="mb-md-0 mb-4 mt-md-2 mx-auto"
              alt="Aula English Time"
            />
          </div>
          <div className="col-lg-6 col-md-7 col-12 text-md-left text-center text-white">
            <p className="lead ">Hemos preparado con éxito para los exámenes de Cambridge, Oxford, Aptis y Trinity estudiantes de todos los niveles, desde A1 hasta C2, apostando por la innovación y clases online.</p>
            <p className="lead ">Ofrecemos clases centradas, dinámicas y muy prácticas impartidas por profesores nativos.</p>
            <p className="lead ">Tu experiencia será una de atención exclusiva o en casos de grupos, en grupos MUY reducidos, para un rendimiento más alto.</p>
            <div className="pt-3">
              <Link href="#" className="btn btn-xl btn-light" to="contactoForm" smooth={"easeInOutCubic"} duration={1500} offset={-103}>Contáctanos</Link>
            </div>
          </div>
        </div>
      </div>
    </SectionDigital>
  );
}

export default ChunkDigital;
