import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ModalContact from 'aulatime/src/components/ModalContact';
import SectionExamenes from 'aulatime/src/components/SectionExamenes';

import LogoCambridge from '../img/logo_cambridge.png';
import LogoAptis from '../img/logo_aptis.png';
import LogoIELTS from '../img/logo_IELTS.png';
import LogoOxford from '../img/logo_oxford.png';
import LogoTrinity from '../img/logo_trinity.png';

const ChunkExamenes = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            station {
              metadata {
                domain
                shortcode
              }
            }
          }
        }
      }
    `
  );

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'es' },
      ...{ form: 'contact', section: '#Examenes' }
    }
  };

  return (
    <SectionExamenes>
      <div className="container">
        <div className="row">

          <div className="col text-center">
            <h1 className="pb-3">Exámenes</h1>
          </div>

        </div>
      </div>
      <div className="container">
        <div className="row">

          <div className="col-md-7 col-12 text-md-right text-center">
            <p className="lead">Las clases de preparación de exámenes son comunicativas donde se trabajan todas las destrezas: <span className="text-primary-2">Reading</span>, <span className="text-primary-2">Writing</span>, <span className="text-primary-2">Listening</span> y <span className="text-primary-2">Speaking</span>.</p>

            <p><span className="text-primary-2">Speaking</span>: sabrás los criterios que usan los examinadores para evaluarte y practicarás todas las partes de Speaking.</p>
            <p><span className="text-primary-2">Writing</span>: recibirás las correcciones detalladas de tus redacciones con notas y consejos para mejorar.</p>
            <p><span className="text-primary-2">Listening</span>: aprenderás las estrategias para centrarte y comprender fácilmente todas las partes.</p>
            <p><span className="text-primary-2">Reading</span>: aprenderás a centrarte, comprender todo el vocabulario que está delante de ti y sacar la máxima puntuación en cada tarea.</p>
            <p><span className="text-primary-2">Gramática</span>: recibirás explicaciones claras, las técnicas son prácticas y sencillas y aclaran tus dudas.</p>
            <p><span className="text-primary-2">Vocabulario</span>: con nuestro método aprenderás el 80% del idioma en palabras y expresiones en tiempo record.</p>

            <h3>Prueba de nivel GRATUITA</h3>
            <p>Conoce tu nivel real de inglés antes de empezar.</p>
            <h3>Matrícula GRATUITA</h3>
            <div className="pt-3">
              <ModalContact
                button={(onClick) => (
                  <button type="button" className="btn btn-xl btn-primary-2" onClick={onClick}>¡Reserva tu plaza ya!</button>
                )}
                postmanpConfig={postmanpConfig}
              />
            </div>
          </div>

          <div className="col-md-5 col-12 text-md-left text-center pl-md-3 pt-md-0 pt-3">
            <div className="row row-cols-md-1 row-cols-2 align-items-center justify-content-center">

                <div className="col">
                  <a href="https://www.cambridgeenglish.org" target="_blank" rel="noopener noreferrer" aria-label="Cambridge"><img src={LogoCambridge} alt="Cambridge" /></a>
                </div>
                <div className="col">
                  <a href="https://www.britishcouncil.org/exam/aptis" target="_blank" rel="noopener noreferrer" aria-label="Aptis"><img src={LogoAptis} alt="Aptis" /></a>
                </div>
                <div className="col">
                  <a href="https://www.ielts.org" target="_blank" rel="noopener noreferrer" aria-label="IELTS"><img src={LogoIELTS} alt="IELTS" /></a>
                </div>
                <div className="col">
                  <a href="https://elt.oup.com" target="_blank" rel="noopener noreferrer" aria-label="Oxford"><img src={LogoOxford} alt="Oxford" /></a>
                </div>                
                <div className="col">
                  <a href="https://www.trinitycollege.com/qualifications/english-language" target="_blank" rel="noopener noreferrer" aria-label="Trinity"><img src={LogoTrinity} alt="Trinity" /></a>
                </div>

            </div>
          </div>

        </div>
      </div>
    </SectionExamenes>
  );
}

export default ChunkExamenes;
