import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ModalContact from 'aulatime/src/components/ModalContact';
import SectionCursos from 'aulatime/src/components/SectionCursos';

import DiagonalLine from 'aulatime/src/components/DiagonalLine';

const CursosBonos = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            station {
              metadata {
                domain
                shortcode
              }
            }
          }
        }
      }
    `
  );

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'es' },
      ...{ form: 'book', section: '#Bonos' }
    }
  };

  const contactHeader = '¡Reserva ya!';
  const button = (onClick) => (
    <button type="button" className="btn btn-lg btn-primary" onClick={onClick}>¡Lo quiero!</button>
  );

  const col1 = <div className="col">
    <div className="card card-01">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <div>
                <h5>8 horas</h5>
              </div>
              <DiagonalLine/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>10 horas</h4>
              <h6>de tutoría privada</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3">
          <h5>€149,<span>99</span></h5>
          <p className="small">IVA incl.</p>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">Establecer una base sólida de vocabulario</li>
          <li className="list-group-item">Mejorar tu acento y práctica para hablar como un nativo</li>
          <li className="list-group-item">Prácticas de todas las destrezas para el examen</li>
        </ul>
        <div>
          <ModalContact
            header={contactHeader}
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#Bono10Horas"
          />
        </div>
      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  const col2 = <div className="col">
    <div className="card card-01">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <div>
                <h5>12 horas</h5>
              </div>
              <DiagonalLine/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>15 horas</h4>
              <h6>de tutoría privada</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3">
          <h5>€199,<span>99</span></h5>
          <p className="small">IVA incl.</p>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">Establecer una base sólida de vocabulario</li>
          <li className="list-group-item">Mejorar tu acento y práctica para hablar como un nativo</li>
          <li className="list-group-item">Prácticas de todas las destrezas para el examen</li>
        </ul>
        <div>
          <ModalContact
            header={contactHeader}
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#Bono15Horas"
          />
        </div>
      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  const col3 = <div className="col">
    <div className="card card-02">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <div>
                <h5>16 horas</h5>
              </div>
              <DiagonalLine/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>20 horas</h4>
              <h6>de tutoría privada</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3">
          <h5>€249,<span>99</span></h5>
          <p className="small">IVA incl.</p>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">Establecer una base sólida de vocabulario</li>
          <li className="list-group-item">Mejorar tu acento y práctica para hablar como un nativo</li>
          <li className="list-group-item">Prácticas de todas las destrezas para el examen</li>
        </ul>
        <div>
          <ModalContact
            header={contactHeader}
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#Bono20Horas"
          />
        </div>
      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  const header = <div className="container">
    <div className="row">
      <div className="col-lg-10 offset-lg-1 col text-center">
        <h1>Cursos</h1>
        <h2 className="text-primary">Bonos de clases de inglés</h2>
        <p className="lead mb-0">Clases de inglés con horario 100% flexible según tu disponibilidad.</p>
        <p className="lead">Las clases son adaptadas a tu agenda y disponibles hasta agotar el bono.</p>

        <p>Los siguientes bonos de clases son recomendables para un curso de inglés general y preparación para los exámenes oficiales de Cambridge, APTIS y IELTS.</p>
      </div>
    </div>
  </div>

  return (
    <SectionCursos
      id="cursos-bonos"
      header={header}
      cols={[col1, col2, col3]}
    />
  );
}

export default CursosBonos;
