import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SectionBienvenidos from 'aulatime/src/components/SectionBienvenidos';

import DividerLeft from 'aulatime/src/components/DividerLeft';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faUserGraduate, faUserFriends } from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faChalkboardTeacher, faUserGraduate, faUserFriends);

const ChunkBienvenidos = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            freshchat {
              token
              host
              locale
              siteId
              tags
              config {
                headerProperty {
                  appName
                  appLogo
                  backgroundColor
                }
              }
            }
          }
        }
      }
    `
  );
  const fcConfig = data.site.siteMetadata.freshchat;

  return (
    <SectionBienvenidos fcConfig={fcConfig}>
      <div className="container">
        <div className="row">

          <div className="col-lg-10 offset-lg-1 col-12 text-center">
            <h1 className="mb-4">Bienvenidos</h1>
            <p className="lead">La misión de nuestra academía de inglés es ayudarte a <span className="text-primary-2 font-weight-bolder">aprobar el examen de Cambridge B1-PET B2-FCE C1-CAE</span>.</p>
          </div>

        </div>
        <div className="row row-cols-md-2 row-cols-1 proceso pt-3">
          <div className="col">

            <div className="card">
              <div className="card-body">
                <p>En Aula English Time, no queremos que estés en clase toda la vida.</p>
              </div>

              <div className="card-footer">
                <DividerLeft/>
              </div>
            </div>

          </div>
          <div className="col">

            <div className="card">
              <div className="card-body">
                <p>En todos nuestros cursos nos centramos en lograr el objetivo.</p>
              </div>
              <div className="card-footer">
                <DividerLeft/>
              </div>
            </div>

          </div>
        </div>
        <div className="row">

          <div className="col-lg-10 offset-lg-1 col-12 text-center">
            <p className="lead pt-3">En una academia donde las clases se dan en grupos, el rendimiento es más bajo porque cada alumno aprovecha solo un porcentaje de la atención del profesor.</p>
          </div>

        </div>
        <div className="row destacados">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12">
            <h3 className="text-center pt-4 mb-3">¿Qué ofrecemos?</h3>

            <div className="row row-cols-1">

              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="chalkboard-teacher" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5>Clases individuales de 60 minutos reales por cada alumno.</h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="user-friends" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5>Un alumno un profesor.</h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="user-graduate" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5>Fórmulas de éxito para sacar el título en tiempo récord.</h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </SectionBienvenidos>
  );
}
export default ChunkBienvenidos;
