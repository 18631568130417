import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import ModalContact from 'aulatime/src/components/ModalContact';

const SectionProfesionales = () => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          station {
            metadata {
              domain
              shortcode
            }
          }
        }
      }
      img: file(relativePath: { eq: "img_profesionales.png" }) {
        childImageSharp {
          fixed(width: 370) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'es' },
      ...{ form: 'contact', section: '#Profesionales' }
    }
  };

  return (
    <section id="profesionales">
      <div className="container">
        <div className="row">

          <div className="col text-center">
            <h1 className="pb-3">Profesionales</h1>
          </div>

        </div>
      </div>

      <div className="container">
        <div className="row">

          <div className="col-lg-6 col-md-7 col-12 text-md-right text-center">

            <p className="lead">Nos dedicamos también a la enseñanza en clases privadas para <span className="text-primary-2">profesionales</span>.</p>
            <p>Ofrecemos cursos específicos para múltiples sectores incluso: hostelería, financiero, farmacéutico, derecho, aviación y marketing.</p>

            <div className="pt-3">
              <ModalContact
                header="Llama ahora"
                button={(onClick) => (
                  <button type="button" className="btn btn-xl btn-dark" onClick={onClick}>Llama ahora</button>
                )}
                postmanpConfig={postmanpConfig}
              />
            </div>

          </div>

          <div className="col-lg-6 col-md-5 col-12 d-flex">
            <Img
              fixed={data.img.childImageSharp.fixed}
              className="mb-0 mt-5 mt-md-2 mx-auto"
              alt="Profesionales"
            />
          </div>

        </div>
      </div>
    </section>
  );
}

export default SectionProfesionales;
