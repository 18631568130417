import React from 'react';

import CursosBonos from './CursosBonos';
import CursosBonos2 from './CursosBonos2';

const ChunkCursos = () => (
  <section id="cursos">
    <CursosBonos/>
    <CursosBonos2/>
  </section>
);

export default ChunkCursos;
